<template>
  <ul>
    <li v-for="(child, index) in children" :key="index">
      <div
        :ref="index"
        :class="[
          'collapsible',
          child.value.name === layer_name_used ? 'active' : '',
        ]"
        @click="switch_collapsible(index, child)"
      >
        <p style="margin: 0">
          <span v-if="'children' in child"
            ><i class="el-icon-arrow-down"></i>
          </span>
          <span v-else
            ><i
              v-if="child.value.name === layer_name_used"
              class="el-icon-circle-check"
            ></i>
            <i v-else class="el-icon-more-outline"></i>
          </span>
          {{ child.label }}
        </p>
        <span
          v-if="!('children' in child)"
          style="display: in-line; font-size: 0.8rem"
          >描述: {{ child.value.desc }}</span
        >
      </div>

      <Layernavigation
        class="content"
        v-if="'children' in child"
        :children="child.children"
        @select_layer="select_layer"
        :layer_name_used="layer_name_used"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: "Layernavigation",
  props: {
    layer_name_used: String,
    children: {
      type: Array,
      default: () => [],
    },
    collapse: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {},
  methods: {
    switch_collapsible(index, child) {
      const ch = this.$refs[index][0];
      if ("children" in child === true) {
        const icon = ch.childNodes[0].childNodes[0].childNodes[0];
        var content = ch.nextElementSibling;
        if (content.style.display === "block") {
          content.style.display = "none";
          icon.classList.add("el-icon-arrow-down");
          icon.classList.remove("el-icon-arrow-up");
        } else {
          content.style.display = "block";
          icon.classList.remove("el-icon-arrow-down");
          icon.classList.add("el-icon-arrow-up");
        }
        // ch.toggle("active");

        console.log(icon);
      } else {
        console.log(child.value.name, this.layer_name_used);
        //传递信号出去

        this.$emit("select_layer", child.value.name);
      }
    },
    select_layer(data) {
      this.$emit("select_layer", data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
.collapsible {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  // border: none;
  text-align: left;
  // outline: none;
  font-size: 15px;
  width: 100%;
  display: block;
  border-bottom: 2px solid rgb(221, 221, 221);
}
.active,
.collapsible:hover {
  background-color: #ccc;
}
.content {
  padding-left: 0.5rem;
  display: none;
  overflow: hidden;
  background-color: #f1f1f1;
}
ul {
  padding-left: 0.5rem;
  li {
    list-style-type: none;
  }
}
</style>
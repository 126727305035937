<template>
  <div
    :class="[
      'home',
      currentRouteName === 'PublicMapView' ? 'public-content' : '',
    ]"
  >
    <el-breadcrumb separator="/" v-if="currentRouteName !== 'PublicMapView'">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t("map_view.title") }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-block" v-if="currentRouteName !== 'PublicMapView'">
      <div class="page-block-header">
        <div class="content-title">
          <p>{{ $t("map_view.title") }}</p>
        </div>
      </div>
    </div>
    <div class="page-content-fill">
      <div style="width: 100%; height: 100%; display: flex">
        <div id="map" class="map">
          <div
            style="
              z-index: 100000;
              position: absolute;
              max-height: 200px;
              overflow: scroll;
            "
          >
            <img id="legend" />
          </div>
          <div
            id="feature"
            style="
              z-index: 100000000;
              position: absolute;
              float: right;
              right: 0px;
              background: white;
            "
          ></div>
        </div>
        <div class="map-controller">
          <router-link v-if="currentRouteName === 'PublicMapView'" to="/">
            <el-button
              style="width: 100%"
              type="primary"
              icon="el-icon-arrow-left"
              >{{ $t("buttons.go_back_to_login") }}</el-button
            >
          </router-link>

          <el-form ref="form" :model="map_data" size="mini" label-width="80px">
            <el-divider content-position="center">{{
              $t("map_view.controller.map_layers")
            }}</el-divider>
            <div class="layer_select_container">
              <Layernavigation
                :children="map_layer_config"
                @select_layer="select_layer"
                :layer_name_used="layer_name_used"
              />
            </div>

            <!-- <p>{{ layer_desc_used }}</p> -->
            <el-divider content-position="center">{{
              $t("map_view.controller.map_center")
            }}</el-divider>
            <el-form-item :label="$t('map_view.controller.lat')">
              <el-input-number
                style="width: 100%"
                v-model="map_data.center_coordinates.lat"
                @change="reset_center(map_data.center_coordinates)"
                @blur="reset_center(map_data.center_coordinates)"
                controls-position="right"
              >
              </el-input-number>
            </el-form-item>
            <el-form-item :label="$t('map_view.controller.long')">
              <el-input-number
                style="width: 100%"
                v-model="map_data.center_coordinates.long"
                @change="reset_center(map_data.center_coordinates)"
                @blur="reset_center(map_data.center_coordinates)"
                controls-position="right"
              >
              </el-input-number>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import "ol/ol.css";
import Map from "ol/Map";
import { transform } from "ol/proj";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import View from "ol/View";
import LayerGroup from "ol/layer/Group";
import ImageWMS from "ol/source/ImageWMS";
import apiURL from "@/data/api.json";
import Layernavigation from "@/components/Layernavigation.vue";
// import options from "@/data/map_layers.json";
export default {
  name: "MapView",
  components: {
    Layernavigation,
  },
  data() {
    return {
      geo_server_url: "http://202.201.1.43:8080/geoserver/data_publish/wms",
      map: {},
      layer_name_used: "data_publish:hazard-index",
      wms_source_used: {},
      layer_names: [
        {
          name: "hazard-index",
          desc: "1代表最高，0代表最低",
        },
      ],
      map_data: {
        center_coordinates: {
          lat: 103.3,
          long: 36.1,
        },
      },
      map_layer_config: [],
    };
  },
  computed: {
    ...mapState({
      app_name: (state) => state.app_name,
      lang_status: (state) => state.lang_status,
    }),
    currentRouteName() {
      return this.$route.name;
    },
    // layer_name_used() {
    //   return this.layer_names[this.layer_names.length - 1].name;
    // },
    // layer_desc_used() {
    //   return this.layer_names[this.layer_names.length - 1].desc;
    // },
  },
  async mounted() {
    await this.$get_api(apiURL.data.map_layers, null, this.headers4reqs).then(
      (res) => {
        this.map_layer_config = res.options;
      }
    );
    this.init_map(this.map_data.center_coordinates);
  },
  watch: {
    layer_name_used(nv) {
      this.reset_layer(nv);
    },
  },
  methods: {
    select_layer(layer_name) {
      this.layer_name_used = layer_name;
    },
    create_map_layer(layer_name) {
      this.wms_source_used = new TileWMS({
        url: this.geo_server_url,
        params: {
          service: "WMS",
          version: "1.1.0",
          request: "GetMap",
          layers: layer_name,
          srs: "EPSG:4326",
          format: "image/png",
        },
        serverType: "geoserver",
        transition: 500,
      });
      return new LayerGroup({
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          new TileLayer({
            source: this.wms_source_used,
          }),
        ],
      });
    },
    init_map(coordinates) {
      this.map = new Map({
        layers: [this.create_map_layer(this.layer_name_used)],
        target: "map",
        view: new View({
          center: transform(
            [coordinates.lat, coordinates.long],
            "EPSG:4326",
            "EPSG:3857"
          ),
          zoom: 8,
        }),
      });
      this.update_legend(this.map.getView().getResolution());
      // 事件监听
      //缩放改变legend
      // Update the legend when the resolution changes
      this.map.getView().on("change:resolution", (e) => {
        this.update_legend(e.target.getResolution());
      });

      //点击
      this.map.on("click", (e) => {
        console.log(transform(e.pixel, "EPSG:3857", "EPSG:4326"));
        const coordinate_0 = transform(e.coordinate, "EPSG:3857", "EPSG:4326");
        const coordinate_1 = transform(
          [e.coordinate[0] + e.pixel[0], e.coordinate[1] + e.pixel[1]],
          "EPSG:3857",
          "EPSG:4326"
        );
        const bbox = [
          coordinate_0[0],
          coordinate_0[1],
          coordinate_1[0],
          coordinate_1[1],
        ];
        return bbox;
        // console.log(bbox);
      });
      this.map.on("singleclick", (e) => {
        console.log(this.map.getLayers().item(0));
        const viewResolution = this.map.getView().getResolution();
        console.log(e.coordinate);
        const url = this.wms_source_used.getFeatureInfoUrl(
          e.coordinate,
          viewResolution,
          "EPSG:3857",
          {
            INFO_FORMAT: "text/html",
            QUERY_LAYERS: this.layer_name_used,
          }
        );
        console.log(url);
        if (url) {
          fetch(url)
            .then((response) => response.text())
            .then((html) => {
              document.getElementById("feature").innerHTML = html;
            });
        }
      });
    },
    reset_center(coordinates) {
      this.map
        .getView()
        .setCenter(
          transform(
            [coordinates.lat, coordinates.long],
            "EPSG:4326",
            "EPSG:3857"
          )
        );
    },
    reset_layer(layer_name) {
      this.map.setLayerGroup(this.create_map_layer(layer_name));
      this.update_legend(this.map.getView().getResolution());
    },
    update_legend(resolution) {
      const wmsSource = new ImageWMS({
        url: this.geo_server_url,
        params: { LAYERS: this.layer_name_used, VERSION: "1.1" },
        ratio: 1,
        serverType: "geoserver",
      });
      var graphicUrl = wmsSource.getLegendUrl(resolution);
      var img = document.getElementById("legend");
      img.src = graphicUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
.map {
  width: 100%;
  height: 100%;
  // border-radius: $card-border-radius;
  // overflow: hidden;
  position: relative;
}
.map-controller {
  width: 300px;
  // height: 100%;
  padding: $site-area-space;
  padding-right: 0rem;
  .el-divider__text {
    white-space: nowrap;
  }
  .el-form-item__label {
    padding-right: 0.5rem;
  }
  .el-form-item {
    margin-bottom: $site-area-space/2;
  }
  .control-input-label {
    min-width: 65px !important;
    display: block;
    text-align: center;
  }
  .layer_select_container {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}
</style>
<style lang="scss" >
@import "@/style/main";
.public-content {
  height: 100vh !important;
  .page-content-fill {
    overflow-y: hidden;
    padding: 0;

    .map-controller {
      padding-right: $site-area-space !important;
    }
  }
}
</style>